import Cookies from 'js-cookie';
import actions from './actions';
import { loginSuperadmin } from '../../lib/api-login';
import { userLogin } from '../../lib/api-users';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = ({ username, password }) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const response = await loginSuperadmin({ username, password });

      if (response.data.Status === 'Success') {
        const { token } = response.data;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        const tokenDetails = `token=${token}; expires=${expirationDate.toUTCString()}; path=/; Secure`;

        Cookies.set('logedIn', true);
        Cookies.set('token', tokenDetails);

        return dispatch(loginSuccess({ login: true, token: tokenDetails }));
      } else {
        return dispatch(loginErr('Login unsuccessful. Status:', response.data.Status));
      }
    } catch (err) {
      return dispatch(loginErr(err.message));
    }
  };
};

const userLoginAction = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const response = await userLogin(userData);

      if (response.data.Status === true) {
        // const { roleid, fname, lastName } = response.data;
        // const expirationDate = new Date();
        // expirationDate.setDate(expirationDate.getDate() + 7);
        // const tokenDetails = `token=${token}; expires=${expirationDate.toUTCString()}; path=/; Secure`;

        Cookies.set('logedIn', true);
        Cookies.set('userData', JSON.stringify(response.data));
        // Cookies.set('token', tokenDetails);

        return dispatch(loginSuccess({ login: true, ...response.data }));
      } else {
        return dispatch(loginErr('Login unsuccessful. Status:', response.data.Status));
      }
    } catch (err) {
      return dispatch(loginErr(err.message));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('token');
      return dispatch(logoutSuccess({ login: false, token: null }));
    } catch (err) {
      return dispatch(logoutErr(err.message));
    }
  };
};

const userLogout = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('userData');
      return dispatch(logoutSuccess({ login: false }));
    } catch (err) {
      return dispatch(logoutErr(err.message));
    }
  };
};

export { login, logOut, userLoginAction, userLogout };

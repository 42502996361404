import React, { useEffect, useState } from 'react';
import {
  TextField,
  FormControl,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  Grid
} from '@mui/material';
import classes from './Login.module.css';
import logo from '../Login/F.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userLoginAction } from '../../redux/authentication/actionCreator';
import LoadingSpinner from '../ui/LoadingSpinner';

const Login = () => {
  const isAuthenticate = useSelector((state) => state.auth.login);
  const user = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.auth.loading);
  const isError = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonStyle = { backgroundColor: '#ed1c26' };
  const [userData, setUserData] = useState({});

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(userLoginAction(userData));
  };

  useEffect(() => {
    if (isAuthenticate) {
      if (user.roleid !== 2) {
        return navigate('/Records');
      }
      navigate('/Abc');
    }
  }, [isAuthenticate]);

  const handleInputChange = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <div className={classes.blWelcome}>
        <Paper sx={{ padding: '20px', marginBottom: '20px', width: '430px', borderRadius: '0.9%' }}>
          <img src={logo} className={classes.ezmeterlogo} alt="EZMETER" />

          <h2 className={classes.pageHead}>Login</h2>
          {isError && (
            <p
              style={{
                backgroundColor: 'rgba(237, 28, 38, .2)',
                borderRadius: '5px',
                color: 'rgba(237, 28, 38, 1)',
                margin: '15px 0',
                padding: '10px'
              }}
            >
              {isError}
            </p>
          )}

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    onChange={handleInputChange}
                    variant="outlined"
                    label="Email"
                    name="emailid"
                    id="email"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    onChange={handleInputChange}
                    variant="outlined"
                    label="Password"
                    type="password"
                    name="password"
                    id="password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
             
              </Grid>
              <Grid item xs={6}>
               
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  style={buttonStyle}
                  onClick={handleLogin}
                  className="ml6"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default Login;

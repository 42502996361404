import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GridOnIcon from '@mui/icons-material/GridOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SignpostIcon from '@mui/icons-material/Signpost';
import AddchartIcon from '@mui/icons-material/Addchart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SourceIcon from '@mui/icons-material/Source';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExploreIcon from '@mui/icons-material/Explore';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
 
const MainListItems = () => {
  const user = useSelector((state) => state.auth);
 
 
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  // const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
 
  const handleClick = () => {
    setOpen(!open);
    setOpen1(false);
    setOpen3(false);
    setOpen5(false);
    setOpen6(false);
  };
 
  const handleClick1 = () => {
    // setOpen(false);
    setOpen1(!open1);
    // setOpen2(false);
    setOpen3(false);
    setOpen6(false);
    setOpen5(false);
  };
 
  // const handleClick2 = () => {
  //   setOpen(false);
  //   setOpen1(false);
  //   setOpen2(!open2);
  //   setOpen3(false);
  //   setOpen6(false);
  //   setOpen5(false);
  // };
 
  const handleClick3 = () => {
    // setOpen(false);
    setOpen1(false);
    // setOpen2(false);
    setOpen3(!open3);
    setOpen6(false);
    setOpen5(false);
  };
 
  const handleClick4 = () => {
    // setOpen(false);
    setOpen1(false);
    // setOpen2(false);
    setOpen3(false);
    setOpen5(false);
    setOpen6(false);
    setOpen4(!open4);
  };
 
  const handleClick5 = () => {
    // setOpen(false);
    setOpen1(false);
    // setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen6(false);
    setOpen5(!open5);
  };
 
  const handleClick6 = () => {
    // setOpen(false);
    setOpen1(false);
    // setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(!open6);
  };
 
  return (
   
<>
  {user.roleid === 3 ? (
    <>
    <NavLink to="/Records" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Dashboard" arrow>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <Tooltip placement="right-start" title="WICS Data" arrow>
    <ListItemButton onClick={handleClick4}>
      <ListItemIcon>
        <SimCardDownloadIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="WICS Data" />
      {open4 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    </Tooltip>
    <Collapse in={open4} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/ImportExcel" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Import WICS File" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Import WICS File" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/ViewArchive" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="View Archive" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <CloudDownloadIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="View Archive" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
      </List>
    </Collapse>
    <NavLink to="/View" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="View Master Data" arrow>
      <ListItemButton>
        <ListItemIcon>
          <GridOnIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="View Master Data" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/Assigning" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Assigning CUID" arrow>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Assigning CUID" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/Scheduling" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Scheduling CUID" arrow>
      <ListItemButton>
        <ListItemIcon>
          <PendingActionsIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Scheduling CUID" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/permitreimbursement" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Permit Reimbursement" arrow>
      <ListItemButton>
        <ListItemIcon>
          <WorkspacePremiumIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Permit Reimbursement" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/permit-status" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Permit Reimbursement Status" arrow>
          <ListItemButton>
            <ListItemIcon>
              <BarChartIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Permit Status" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
    <NavLink to="/Emergencywork" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Emergency Work" arrow>
      <ListItemButton>
        <ListItemIcon>
          <ReportProblemIcon sx={{ color: '#d3252b' }} />
        </ListItemIcon>
        <ListItemText primary="Emergency Work" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/revenuestatus" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Revenue Status" arrow>
      <ListItemButton>
        <ListItemIcon>
          <SettingsBackupRestoreIcon sx={{ color: 'white' }}  />
        </ListItemIcon>
        <ListItemText primary="Revenue Status" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/permit" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Permit" arrow>
      <ListItemButton>
        <ListItemIcon>
          <ExploreIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Permit" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <Tooltip placement="right-start" title="User Mangement" arrow>
    <ListItemButton onClick={handleClick1}>
      <ListItemIcon>
        <ManageAccountsIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="User Mangement" />
      {open1 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    </Tooltip>
    <Collapse in={open1} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/UserList" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="User List" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="User List" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/AddUser" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Add User" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <PersonAddIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Add User" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
      </List>
    </Collapse>
   

   <Tooltip placement="right-start" title="Item" arrow>
    <ListItemButton onClick={handleClick3}>
      <ListItemIcon>
        <ConstructionIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Item" />
      {open3 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    </Tooltip>
    <Collapse in={open3} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/ListItem" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Item List" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Item List" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/AddItem" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Add Item" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddShoppingCartIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Add Item" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
      </List>
    </Collapse>
    <NavLink to="/Exception" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Exception" arrow>
      <ListItemButton>
        <ListItemIcon>
          <SyncProblemIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Exception" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <NavLink to="/Crew" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Crew Master" arrow>
      <ListItemButton>
        <ListItemIcon>
          <GroupAddIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Crew Master" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
    <Tooltip placement="right-start" title="Status" arrow>
    <ListItemButton onClick={handleClick6}>
      <ListItemIcon>
        <SignpostIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Status" />
      {open6 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    </Tooltip>
    <Collapse in={open6} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/ListStatus" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Status List" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Status List" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/AddStatus" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Add Status" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddchartIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Add Status" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
      </List>
    </Collapse>
    <ListItemButton onClick={handleClick}>
  <ListItemIcon>
    <ReceiptIcon  sx={{color:'white'}}/>
  </ListItemIcon>
  <ListItemText primary="Invoice" />
  {open ? <ExpandLess /> : <ExpandMore />}
</ListItemButton>
<Collapse in={open} timeout="auto" unmountOnExit>
  <List component="div" disablePadding>
  <NavLink to="/ListInvoice" style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItemButton sx={{ pl: 4 }}>
      <ListItemIcon>
        <ReceiptLongIcon  sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary="List Invoice" />
    </ListItemButton>
    </NavLink>
    <NavLink to="/AddInvoice" style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItemButton sx={{ pl: 4 }}>
      <ListItemIcon>
        <PostAddIcon  sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary="Create Invoice" />
    </ListItemButton>
    </NavLink>
  </List>
</Collapse>
 
    <Tooltip placement="right-start" title="Settings" arrow>
    <ListItemButton onClick={handleClick5}>
      <ListItemIcon>
        <SettingsIcon sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText primary="Settings" />
      {open5 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    </Tooltip>
    <Collapse in={open5} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/emailSetting" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="SMTP" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AttachEmailIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="SMTP" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Other" arrow>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <PostAddIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Other" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
      </List>
    </Collapse>
    {/* <NavLink to="/Reports" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Reports" arrow>
      <ListItemButton>
        <ListItemIcon>
          <AssessmentIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
      </Tooltip>
    </NavLink> */}
  </>
  ) : (
    <>
      {user.roleid !== 2 && (
        <>
        <NavLink to="/Records" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Dashboard" arrow>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <Tooltip placement="right-start" title="WICS Data" arrow>
        <ListItemButton onClick={handleClick4}>
          <ListItemIcon>
            <SimCardDownloadIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="WICS Data" />
          {open4 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        </Tooltip>
        <Collapse in={open4} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink to="/ImportExcel" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Import WICS File" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ListAltIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Import WICS File" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink to="/ViewArchive" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="View Archive" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <CloudDownloadIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="View Archive" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
          </List>
        </Collapse>
        <NavLink to="/View" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="View Master Data" arrow>
          <ListItemButton>
            <ListItemIcon>
              <GridOnIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="View Master Data" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/Assigning" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Assigning CUID" arrow>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Assigning CUID" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/Scheduling" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Tooltip placement="right-start" title="Scheduling CUID" arrow>
      <ListItemButton>
        <ListItemIcon>
          <PendingActionsIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Scheduling CUID" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
        <NavLink to="/permitreimbursement" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Permit Reimbursement" arrow>
          <ListItemButton>
            <ListItemIcon>
              <WorkspacePremiumIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Permit Reimbursement" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/permit-status" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Permit Reimbursement Status" arrow>
          <ListItemButton>
            <ListItemIcon>
              <BarChartIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Permit Status" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/Emergencywork" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Emergency Work" arrow>
          <ListItemButton>
            <ListItemIcon>
              <ReportProblemIcon sx={{ color: '#d3252b' }} />
            </ListItemIcon>
            <ListItemText primary="Emergency Work" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/permit" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Permit" arrow>
          <ListItemButton>
            <ListItemIcon>
              <ExploreIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Permit" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <Tooltip placement="right-start" title="User Mangement" arrow>
        <ListItemButton onClick={handleClick1}>
          <ListItemIcon>
            <ManageAccountsIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="User Mangement" />
          {open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        </Tooltip>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink to="/UserList" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="User List" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ListAltIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="User List" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink to="/AddUser" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Add User" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PersonAddIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Add User" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
          </List>
        </Collapse>
        {/* <ListItemButton onClick={handleClick2}>
      <ListItemIcon>
        <EngineeringIcon  sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary="Contractor" />
      {open2 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open2} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
      <NavLink to="/ListContractor" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <ListAltIcon sx={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText primary="Contractor List" />
        </ListItemButton>
        </NavLink>
        <NavLink to="/AddContractor" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <GroupAddIcon sx={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText primary="Add Contractor" />
        </ListItemButton>
        </NavLink>
      </List>
    </Collapse> */}

       <Tooltip placement="right-start" title="Item" arrow>
        <ListItemButton onClick={handleClick3}>
          <ListItemIcon>
            <ConstructionIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Item" />
          {open3 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        </Tooltip>
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink to="/ListItem" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Item List" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ListAltIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Item List" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink to="/AddItem" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Add Item" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddShoppingCartIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Add Item" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
          </List>
        </Collapse>
        <NavLink to="/Exception" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Exception" arrow>
          <ListItemButton>
            <ListItemIcon>
              <SyncProblemIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Exception" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/Crew" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Crew Master" arrow>
          <ListItemButton>
            <ListItemIcon>
              <GroupAddIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Crew Master" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <Tooltip placement="right-start" title="Status" arrow>
        <ListItemButton onClick={handleClick6}>
          <ListItemIcon>
            <SignpostIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Status" />
          {open6 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        </Tooltip>
        <Collapse in={open6} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink to="/ListStatus" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Status List" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ListAltIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Status List" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink to="/AddStatus" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Add Status" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddchartIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Add Status" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <ReceiptIcon  sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary="Invoice" />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
      <NavLink to="/ListInvoice" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <ReceiptLongIcon  sx={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText primary="List Invoice" />
        </ListItemButton>
        </NavLink>
        <NavLink to="/AddInvoice" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <PostAddIcon  sx={{color:'white'}}/>
          </ListItemIcon>
          <ListItemText primary="Create Invoice" />
        </ListItemButton>
        </NavLink>
      </List>
    </Collapse>
 
        <Tooltip placement="right-start" title="Settings" arrow>
        <ListItemButton onClick={handleClick5}>
          <ListItemIcon>
            <SettingsIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Settings" />
          {open5 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        </Tooltip>
        <Collapse in={open5} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink to="/emailSetting" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="SMTP" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AttachEmailIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="SMTP" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
            <NavLink to="" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Tooltip placement="right-start" title="Other" arrow>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PostAddIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Other" />
              </ListItemButton>
              </Tooltip>
            </NavLink>
          </List>
        </Collapse>
        {/* <NavLink to="/Reports" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Reports" arrow>
          <ListItemButton>
            <ListItemIcon>
              <AssessmentIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
          </Tooltip>
        </NavLink> */}
      </>
      )}
      {user.roleid === 2 && (
        <>
        <NavLink to="/Abc" style={{ textDecoration: 'none', color: 'inherit' }}>
           <Tooltip placement="right-start" title="View Files" arrow>
          <ListItemButton>
            <ListItemIcon>
              <SourceIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="View Files" />
          </ListItemButton>
          </Tooltip>
        </NavLink>
        <NavLink to="/WgException" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right-start" title="Exception Report" arrow>
      <ListItemButton>
        <ListItemIcon>
          <SummarizeIcon sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Exception Report" />
      </ListItemButton>
      </Tooltip>
    </NavLink>
      </>
      )}
    </>
  )}
</>
  );
};
 
export default MainListItems;
import React, { useState, useEffect } from 'react'
import LoadingSpinner from '../../components/ui/LoadingSpinner'
import {  Alert, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import { getExceptionRecords } from '../../lib/api-records'
import * as XLSX from 'xlsx';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate,useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { Box } from '@mui/system';


const useStyles = makeStyles({
  blueHeader: {
    backgroundColor: '#2595be9c',
    color: '#000000',
  },
});

const GlobalSearch = () => {
const [isLoading, setIsLoading ] = useState(false);
const navigate = useNavigate();
const location = useLocation();
const { tableData } = location.state || {};
const classes = useStyles();



const columns = [
    { headerClassName: classes.blueHeader,field: 'wrNo', headerName: 'wrNo', flex:1},
    {
      headerClassName: classes.blueHeader,
      field: 'bcaId',
      headerName: 'BCA-ID',
      flex:1
    },
    {
      headerClassName: classes.blueHeader,
      field: 'address',
      headerName: 'Address',
      flex:1
    }
  ];




  const handleCellClick = (params)=>{
    if (params.field === "wrNo") {
        navigate(`/cutInfo/${params.id}`);
    }
  }
  
  


  return (
    <>
 
    <Typography
       sx={{ float: 'left', fontFamily: 'Roboto', fontSize: 21, marginBottom: 2, marginLeft: 2 }}
     >
      Global Search
     </Typography>
     <br />
     <br />
     <br />

     <Box sx={{ height: 400, width: '100%' , margin:'auto', marginTop:4, cursor:'pointer'}}>

 <DataGrid
   rows={tableData}
   density='compact'
   columns={columns}
   initialState={{
     pagination: {
       paginationModel: {
         pageSize: 10,
       },
     },
   }}
   pageSizeOptions={[5]}
   getRowId={(row) => row.id}
   onCellClick={handleCellClick}
 />
</Box>
   </>
  )
}

export default GlobalSearch

import axios from 'axios';
import { USERS_BASE_PATH } from './api-base-paths';
 
export const addUser = (data, controller) => {
  return axios.post(`${USERS_BASE_PATH}/adduser`, data, {
    signal: controller?.signal
  });
};
 
export const getAllUsers = (controller) => {
  try {
    return axios.get(`${USERS_BASE_PATH}/getAllUsers`, {
      signal: controller?.signal
    });
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
export const getuserinfo = (id) => {
  console.log("yooooo", id);
 
  return axios.get(`${USERS_BASE_PATH}/getuserinfo/${id }`);
};
export const updategetuserinfo = ( data, controller) => {
 
  return axios.put(`${USERS_BASE_PATH}/updategetuserinfo`, data, {
    signal: controller?.signal,
  });
};
export const getUserById = (id) => {
  return axios.post(`${USERS_BASE_PATH}/getUserById`, id);
};
 
export const updateUser = (data, controller) => {
  return axios.post(`${USERS_BASE_PATH}/updateUser`, data, {
    signal: controller?.signal
  });
};
 
export const updateUserPassword = (data, controller) => {
  return axios.post(`${USERS_BASE_PATH}/updatePassword`, data, {
    signal: controller?.signal
  });
};
 
export const deleteUser = (id, controller) => {
  return axios.delete(`${USERS_BASE_PATH}/deleteUser/${id}`, {
    signal: controller?.signal
  });
};
 
export const userLogin = (data) => {
  return axios.post(`${USERS_BASE_PATH}/login`, data);
};
 